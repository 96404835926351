import React from "react"
import "./default-content-block.css"

export default class DefaultContentBlock extends React.Component {
  render() {
    return (
      <div className="default-content-block">
        <div className="default-content-block__left">
          <div className="default-content-block__title">{this.props.title}</div>
        </div>
        <div className="default-content-block__right">
          <div className="default-content-block__subtitle">
            {this.props.subtitle}
          </div>
          <div className="default-content-block__content">
            {this.props.content}
          </div>
        </div>
      </div>
    )
  }
}
