import React from "react"
import "./centered-content-block.css"

export default class CenteredContentBlock extends React.Component {
  render() {
    return (
      <div className="centered-content-block">
          <div className="centered-content-block__title">{this.props.title}</div>
          
          <div className="centered-content-block__subtitle">
            {this.props.subtitle}
          </div>
          <div className="centered-content-block__content">
            {this.props.content}
          </div>
      </div>
    )
  }
}
