import React from "react"
import "./card-layout.css"

import Card from "../../components/card/card"
import CardOne from "../../../static/media/tech-check.png";
import CardTwo from "../../../static/media/value-check.png";
import CardThree from "../../../static/media/full-check.png";

export default class CardLayout extends React.Component {
  render() {
    return (
      <div class="card-layout-container">
        <h1 class="container centered-content-block__title">Hoe wij u daarbij kunnen helpen:</h1>
        <div className="card-layout">
          <Card
            title="Tech-check"
            price="Vanaf €350 excl. btw"
            imagePath={CardOne}
            actionText="meer info"
            actionUrl="/tech-check"
            description="Complete technische doorlichting voor aankoop. Op basis van 400 punten doorgronden we de hele eigendom zodat ze voor jullie geen verassingen meer heeft."
          ></Card>
          <Card
            title="Value-check"
            price="Vanaf €550 excl. btw"
            imagePath={CardTwo}
            actionUrl="/value-check"
            actionText="meer info"
            description="Extra doorlichting op juridische punten, objectieve waarde-bepaling, argumenten bij uw prijs-onderhandeling, hulp zoals we het voor ons zelf zouden doen."
          ></Card>
          <Card
            title="Ready-to-buy-check"
            price="vanaf 750 € excl. btw"
            imagePath={CardThree}
            actionText="meer info"
            actionUrl="/full-check"
            description="Bovenop de Tech-check krijgt u tal van tips & tricks bij de aankooponderhandelingen"
          ></Card>
        </div>
      </div>
    )
  }
}
