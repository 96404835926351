import React from "react"
import "./header.css"
import LineAnimation from "../line-animation/line-animation"

export default class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <div className="header__back-ground">
          <div className="header__content">
            <div class="container">
              <h1 class="default-content-block__title">U overweegt de aankoop van een woning of appartement?</h1>
              <LineAnimation color="rgb(30, 30, 30)" margin="0 auto" position="center"></LineAnimation>
              <h1 className="header__title">Vastgoed Aankoopbegeleiding en Bemiddeling</h1>
              <h2 className="header__subtitle">By Patrick Landuyt</h2>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
