import React from "react"
import "./card.css"
import FontAwesome from "react-fontawesome"
import { Link } from "gatsby"

export default class Card extends React.Component {
  render() {
    const cardImageStyle = {
      backgroundImage: "url(" + this.props.imagePath + ")",
    }

    return (
        <Link className="card" to={this.props.actionUrl}>
          <div className="card__image" style={cardImageStyle}>
            <span></span>
          </div>
          <div className="card__container">
            <h1 className="card__title">{this.props.title}</h1>
            <h2 className="card__price">{this.props.price}</h2>
            <p className="card__description">{this.props.description}</p>
            <div className="card__action">
              {this.props.actionText}
              <span>
                <FontAwesome
                  className="fas fa-long-arrow-alt-right"
                  name="arrow"
                  style={{ color: "#2e8b57", marginLeft: "10px" }}
                />
              </span>
            </div>
          </div>
        </Link>
    )
  }
}
