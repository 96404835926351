import React from "react"

import DefaultLayout from "../layouts/default-layout"
import DefaultContentBlock from "../components/default-content-block/default-content-block"
import CenteredContentBlock from "../components/centered-content-block/centered-content-block"
import CardLayout from "../layouts/card-layout/card-layout"
import Layout from '../layouts/layout/layout'
import Header from '../components/header/header'
import ContactButton from '../components/contact-button/contact-button'
import { Helmet } from "react-helmet"
import StressedCoupleImage from "../../static/media/Image_550887760.png"

export default () => (
  <Layout>
    <Helmet>
      <title>Vastgoed aankoopbegeleding</title>
      <meta name="description" content="Een eigendom kopen doe je niet alle dagen. Spaar veel geld uit met onze 3 checks en onze begeleiding bij het aankopen van een pand of huis."></meta>
      <meta name="keywords" context="kopen, asbest, vastgoed, aakoopbegeleiding, huis, pand, immo, immophone, gent, De Pinte"></meta>          
    </Helmet>
    <Header></Header>

    <DefaultLayout>
      <DefaultContentBlock
        title={<img src={StressedCoupleImage} style={{width: '100%'}}></img>}
        content={<h3><div class="headaches">We verdedigen uw belangen als koper en besparen u véle euro’s & kopzorgen!</div></h3>}>
      </DefaultContentBlock>
      <div style={{height: '.05rem', width: '100%', backgroundColor: 'rgb(240,240,240)'}}></div>
    </DefaultLayout>

    <DefaultLayout>
      <DefaultContentBlock
        title="Een eigendom aankopen zonder zorgen."
        // subtitle="Een objectieve en deskundige beoordeling van uw toekomstige eigendom door experten kan u veel geld en zorgen besparen."
        subtitle={<div>
          Eigendommen worden vaak &ndash; bewust of onbewust &ndash; te mooi voorgesteld. Zaken worden verdoezeld of je ziet het gewoon niet.<br></br><br></br>
          Wat uw droomhuis moest worden, werd een nachtmerrie! Een objectieve en deskundige beoordeling door experten kan u veel besparen, zowel financieel als emotioneel.<br></br><br></br>
          Soms gebeurt een aankoop onder bepaalde tijdsdruk en neem je daarom foute beslissingen. Die gevolgen kunnen nog lange tijd nazinderen.</div>}
      ></DefaultContentBlock>
    </DefaultLayout>

    <div className="bg-green">
      <DefaultLayout>      
        <CenteredContentBlock
          title="Wie zijn we?"
          content={<div>
          Gepokt en gemazeld door de wereld van vastgoed. Door zijn kennis en ervaring in aankoop, renovatie en verkoop, weet Patrick Landuyt als géén ander dit om te zetten in waardevolle adviezen aan ieder, alsof het voor zichzelf zou zijn.<br></br><br></br>
          Als erkend en onafhankelijk professional weet hij als géén ander zijn opgedane onderhandelingstalent in te zetten om u te begeleiden in iedere fase van het koopproces.<br></br><br></br>
          Dit kan u véle kopzorgen en euro’s besparen.</div>}
          ></CenteredContentBlock>
      </DefaultLayout>
    </div>

    <DefaultLayout>
      <CardLayout></CardLayout>
      <ContactButton></ContactButton>
    </DefaultLayout>
  </Layout>
)
 